import "./styles.less";

import React, { useContext } from "react";

import { Radio, Select } from "antd";
import { useOperationType } from "./OperationType.hook";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { IC_Collapse } from "shared-components/Components/Collapse/web";
import { extractOperationValues, firstLetterToUppercase, getOperationId } from "src/utils/Functions";

const { Option } = Select;

export const OperationType = ({ collapsable = false, home = false, ...props }) => {
	const { country_code, translations } = useContext(ConfigStateContext);
	const isCeee = country_code === "ce3";
	const {
		show,
		error,
		loading,
		inputType,
		data: { options, value, onChange },
	} = useOperationType(props);
	const { showSeasonInput, handleChangeSeason } = props;

	if (!show || error) return null;

	const opts = options ?? [];
	const optsSeason = [
		{
			value: 0,
			title: "Buscar por Calendario"
		},
		{
			value: 1,
			title: "Buscar por Temporada"
		}
	]

	const handleChange = e => {
		const values = extractOperationValues(e)
		const res = options.filter(o => o.value === values.id && o.projects === values.projects);

		onChange(res[0]);
	};

	const filterRadio = (
		<Radio.Group onChange={e => handleChange(e.target.value)} value={value} disabled={loading}>
			{opts
				.filter(o => o.text !== "Vacacional")
				.map(o => (
					<Radio value={getOperationId(o.value, o.projects)} key={`key_${getOperationId(o.value, o.projects)}_operationType`}>{o.text}</Radio>
				))
			}
		</Radio.Group>
	);

	const filterButtons = (
		isCeee ? (
			<Radio.Group
				defaultValue={Number(showSeasonInput)}
				buttonStyle="solid"
				optionType="button"
				onChange={() => handleChangeSeason(!showSeasonInput)}
			>
				{optsSeason.map((option, index) => (
					<Radio.Button key={`button-season-${index}`} value={option.value}>{option.title}</Radio.Button>
				))}
			</Radio.Group>
		) : country_code === "CO" ? (
			<Radio.Group
				disabled={loading}
				onChange={e => handleChange(e.target.value)}
				optionType="button"
				buttonStyle={"solid"}
				value={value}
			>
				{opts.filter(o => o.text !== "Vacacional").map(o => {
					return (
						<Radio.Button
							key={`key_buttons_${getOperationId(o.value, o.projects)}_operationType`}
							value={getOperationId(o.value, o.projects)}
						>
							{o.text}
						</Radio.Button>
					)
				})}
			</Radio.Group>
		)
			: (
				<Radio.Group
					disabled={loading}
					onChange={e => handleChange(e.target.value)}
					optionType="button"
					buttonStyle={"solid"}
					value={value}
				>
					{opts.map(o => (
						<Radio.Button
							key={`key_buttons_${getOperationId(o.value, o.projects)}_operationType`}
							value={getOperationId(o.value, o.projects)}>
							{o.text}
						</Radio.Button>
					))}
				</Radio.Group>
			)
	);

	const mapSeasonOptions = () => {
		return optsSeason.map((option, index) => (
			<Option key={`option-season-${index}`} value={option.value}>
				{option.title}
			</Option>
		));
	};

	const mapOperationTypeOptions = () => {
		return opts
			.filter(o => o.text !== "Vacacional")
			.map(o =>  {
				return (
				<Option key={`key_select_${o.value}_${o.projects ? "project" : "normal"}_operationType`} value={getOperationId(o.value, o.projects)}>
					{o.text}
				</Option>
			)});
	};

	const mapDefaultOptions = () => {
		return opts.map(o =>  (
			<Option key={`key_select_${o.value}_${o.projects ? "project" : "normal"}_operationType`} value={getOperationId(o.value, o.projects)}>
				{o.text}
			</Option>
		));
	};

	const filterSelect = (
		<Select
			disabled={loading}
			defaultValue={loading ? "Operación" : isCeee ? Number(showSeasonInput) : "1-normal"}
			onChange={e => {
				return (isCeee ? handleChangeSeason(!showSeasonInput) : handleChange(e))
			}}
		>
			{isCeee
				? mapSeasonOptions()
				: country_code === "CO"
					? mapOperationTypeOptions()
					: mapDefaultOptions()}
		</Select>
	);

	let filter = filterButtons;
	if (inputType == "radioselect") filter = filterRadio;
	else if (inputType == "select") filter = filterSelect;

	return (
		<div className="filter operation-type-filter">
			{collapsable
				? <IC_Collapse label={firstLetterToUppercase(translations.transacción)} isActive={!!value} startOpen={!value}>
					{filter}
				</IC_Collapse>
				: filter
			}
		</div>
	);
};
